<template>
  <base-view title="Estructura CFDI" icon="columns">
    <div>
      <check-authorization :requiresAuthorizations="['indice reglas']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Estructura del Comprobante Fiscal Digital
          </b-card-header>

          <b-card-body class="p-2">
            <se-estructura-cfdi-list @on-got-item="showForm = true"></se-estructura-cfdi-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import SeEstructuraCfdiList from '@/components/SE/EstructuraCfdi/SeEstructuraCfdiList'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'estructuraCfdiModule'

export default {
  name: 'SeEstructuraCfdiMainView',

  components: {
    SeEstructuraCfdiList
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, ['isEditingResource'])
  },

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>
