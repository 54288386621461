<template>
  <div>
    <x-select
      v-model="version"
      dispatchPath="versionCfdiModule/getResource"
      getterListPath="versionCfdiModule/getResourceList"
      :formatListWith="['id', 'version']"
      defaultText="-- Selecciona una versión de CFDI --"
      noClearButton
      prependText="Versión CFDI"
    >
    </x-select>
    <tree-table
      v-if="!loading"
      class="table"
      :columns="columns"
      :table-data="getResourceList" />
    <loading v-else></loading>
    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import TreeTable from 'vue-tree-table-component'
import { mapGetters } from 'vuex'

const STORE_MODULE = 'estructuraCfdiModule'

export default {
  name: 'SeEstructuraCfdiList',

  components: {
    TreeTable
  },

  data () {
    return {
      loading: false,

      version: null,

      columns: [
        {
          label: 'Nombre',
          id: 'nombre'
        },
        {
          label: 'Tipo',
          id: 'tipo'
        },
        {
          label: 'Requerido',
          id: 'required'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  watch: {
    version: {
      immediate: true,
      handler (value) {
        if (value) {
          this.submit(value)
        }
      }
    }
  },

  mounted () {
  },

  methods: {
    async submit (version_id) {
      this.loading = true
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`, { version_id })
      this.loading = false

      if (error) this.$notify({ error, message }, 'Reglas')
    },

    getRoleVariant (role) {
      if (role === 'admin') return 'success'

      return 'primary'
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    input (value) {
      console.log(value)
    }
  }
}
</script>

<style scoped>
.table{
  width: 90%;
  margin: auto;
}
</style>
